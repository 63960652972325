import { Ref } from "@vue/composition-api";
import Vue from "vue";

const setFocusTo = (refEl: Ref<any>): void => {
  Vue.nextTick().then(() => {
    const divEl: HTMLElement = refEl.value.$el;
    const el = divEl.getElementsByTagName("input")[0];
    el.focus();
  });
};

export { setFocusTo };
