









































































import { defineComponent } from "@vue/composition-api";
import router from "@/router";
import { SaveContext } from "@/apps/core/models/formModel";
import useFormModel from "@/apps/core/modules/useFormModel";
import UserFM from "../models/userForm";

export default defineComponent({
  name: "ChangePassword",
  setup() {
    const form = new UserFM();
    const composition = useFormModel(form);
    const user = composition.instance;
    const changePassword = () => composition.formRef.save(SaveContext.Update);

    return {
      // user
      user,

      // method
      changePassword,
      goBack: () => router.go(-1),

      // Composition
      ...composition,
    };
  },
});
