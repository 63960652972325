import axios from "axios";
import { AxiosAuthRefreshRequestConfig } from "axios-auth-refresh";
import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";

class Auth extends PlainModel {
  username: string | null = null;
  password: string | null = null;
}

class AuthAPI extends API {
  static modelPath = "/api/token/";

  constructor() {
    super(AuthAPI.modelPath);
  }

  async login(payload: Record<string, any>): Promise<any> {
    const url = this.getAPIUrl();
    try {
      const refreshConfig: AxiosAuthRefreshRequestConfig = {
        skipAuthRefresh: true,
      };
      const response = await axios.post(url, payload, refreshConfig);
      return response.data;
      // akan langsung di-redirect ke dashboard dari login view
    } catch (error) {
      if ([400, 401].includes(error.response.status)) {
        return this.getErrorData(error.response);
      }
      const errorMessage = "Permasalahan pada proses login.";
      // Toast.open(errorMessage)
      throw new Error(errorMessage);
    }
  }
}

export default class AuthVM extends ViewModel {
  constructor() {
    super(new AuthAPI(), new Auth());
  }
}

export { Auth, AuthAPI };
