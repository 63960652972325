




import { defineComponent } from "@vue/composition-api";
import Vue from "vue";
import { isAdmin, isPengelola, isNasabah } from "../modules/store";
import router from "@/router";

export default defineComponent({
  name: "BaseHome",
  setup() {
    let nextRouteName = "not-found";
    if (isPengelola.value || isAdmin.value) {
      nextRouteName = "pengelola-home";
    } else if (isNasabah.value) {
      nextRouteName = "nasabah-home";
    } else {
      nextRouteName = "donor-add";
    }
    Vue.nextTick().then(() => router.replace({ name: nextRouteName }));
  },
});
