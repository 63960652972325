




















































































import { defineComponent, Ref, ref } from "@vue/composition-api";
import { Location } from "vue-router";
import { Auth } from "../models/auth";
import AuthFM from "../models/authForm";
import router from "@/router";
import { setFocusTo } from "@/apps/core/modules/utils/dom";
import coreState from "@/apps/core/modules/store";
import useFormModel from "@/apps/core/modules/useFormModel";
import APP_CONFIG from "@/apps/core/modules/config";
import { isEmail } from "@/apps/core/modules/utils/text";

// const useAuthFormModel = (form: AuthFM) => {
//   const composition = useFormModel(form);
//   const formRef = reactive(form);
//   return {
//     formRef,
//     instance: composition.instance,
//     errorMap: composition.errorMap, // reactive

//     // Computed
//     isValid: composition.isValid, // perlu dicek lagi!!!!!

//     // Method
//     validate: composition.validate,
//   };
// };

export default defineComponent({
  name: "Login",
  setup() {
    const usernameInput: Ref<any> = ref(null);
    const needLogin = true;
    const form = new AuthFM();
    const composition = useFormModel(form);
    const auth = composition.instance as Auth;

    const validateUsername = () => {    
      auth.username = auth.username?.toLowerCase() ?? "";
      if (!isEmail(auth.username)) {
        composition.errorMap["username"] = "Isi dengan alamat email yang benar.";
      } else {
        composition.validate("username");
      }
    }

    const goToNextURL = () => {
      const routerTo: Location = { name: "base-home" };
      const query = router.currentRoute.query;
      if (
        query.next &&
        !(
          query.next === "/base-home" ||
          query.next === "/pengelola/home" ||
          query.next === "/nasabah/home"
        )
      ) {
        coreState.nextUrl = query.next as string;
      }
      router.replace(routerTo);
    };

    const login = () => form.login(goToNextURL);

    setFocusTo(usernameInput);

    return {
      // Data
      auth,
      brandText: APP_CONFIG.brandText,
      needLogin,
      usernameInput,

      // Method
      login,
      validateUsername,

      // Composition
      ...composition,
    };
  },
});
