import FormModel from "@/apps/core/models/formModel";
import {
  RequestResetPassword,
  RequestResetPasswordAPI,
  ResetPassword,
  ResetPasswordAPI,
} from "./password";
import { User, UserAPI } from "./user";

class RequestResetPasswordFM extends FormModel {
  constructor() {
    super(
      new RequestResetPasswordAPI(),
      new RequestResetPassword(),
      ["email"],
      []
    );
  }
}

class ResetPasswordFM extends FormModel {
  constructor() {
    super(
      new ResetPasswordAPI(),
      new ResetPassword(),
      ["newPassword1", "newPassword2"],
      []
    );
  }

  async fetch(): Promise<void> {
    /* Override fetch karena memang tidak ada object-nya. */
  }
}

export default class UserFM extends FormModel {
  constructor() {
    super(new UserAPI(), new User(), ["oldPassword", "newPassword"], []);
  }
}

export { ResetPasswordFM, RequestResetPasswordFM };
