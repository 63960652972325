





























































import { defineComponent } from "@vue/composition-api";
import { ResetPasswordFM } from "../models/userForm";
import router from "@/router";
import { SaveContext } from "@/apps/core/models/formModel";
import useFormModel from "@/apps/core/modules/useFormModel";

export default defineComponent({
  name: "ResetPassword",
  setup() {
    const paramsId = router.currentRoute.params.id;
    const form = new ResetPasswordFM(); // Pastikan fetch-nya sudah di-override
    // set id ke instance, digunakan di save / update method.
    form.instance.id = router.currentRoute.params.id;
    const composition = useFormModel(form, paramsId);
    const reset = async () => {
      await composition.formRef.save(SaveContext.Update, false);
      if (composition.formRef.getIsValid()) {
        router.replace({ name: "login" });
      }
    };

    return {
      // Data
      resetPass: composition.instance,

      // Method
      reset,

      // Composition
      ...composition,
    };
  },
});
