



















































import { defineComponent, ref, Ref } from "@vue/composition-api";
import APP_CONFIG from "@/apps/core/modules/config";
import { RequestResetPasswordFM } from "../models/userForm";
import { SaveContext } from "@/apps/core/models/formModel";
import { setFocusTo } from "@/apps/core/modules/utils/dom";
import useFormModel from "@/apps/core/modules/useFormModel";
import { RequestResetPassword } from "../models/password";
import { isEmail } from "@/apps/core/modules/utils/text";

export default defineComponent({
  name: "RequestResetPassword",
  setup() {
    const emailInput: Ref<any> = ref(null);
    const showMessage = ref(false);
    const form = new RequestResetPasswordFM();
    const composition = useFormModel(form);
    const reqResetPass = composition.instance as RequestResetPassword;
    const requestReset = async () => {
      await composition.formRef.save(SaveContext.Create, false);
      showMessage.value = true;
    };

    setFocusTo(emailInput);

    const validateEmail = () => {    
      reqResetPass.email = reqResetPass.email?.toLowerCase() ?? "";
      if (!isEmail(reqResetPass.email)) {
        composition.errorMap["email"] = "Isi dengan alamat email yang benar.";
      } else {
        composition.validate("email");
      }
    }

    return {
      // Data
      brandText: APP_CONFIG.brandText,
      subBrandText: APP_CONFIG.subBrandText,
      reqResetPass,
      showMessage,
      emailInput,

      // Method
      requestReset,
      validateEmail,

      // Composition
      ...composition,
    };
  },
});
